.virtual {
    font-size: 100px;
    color: #fff;
    font-weight: 300;
    letter-spacing: 15px;
    /* text-shadow: 172px 20px 9px black; */
  }
  .comingsoon-text{
    font-size: 40px;
  }
 .carousel-control-next-icon{
  width: 100px;
  height: 100px;
 } 
 .carousel-control-prev-icon{
  width: 100px;
  height: 100px;
 }
  .landing-vector{
    height: 11px;
    width: 40px;
    background-color: #709da7;
    border-radius: 20px;
  }
  .banner-button{
    background-color: #709DA7;
    color: #fff;
    padding: 10px 30px;
    border-radius: 30px;
    border: 1px solid #709DA7;
    font-weight: 600;

  }
  .landing-border{
    border-top: 15px solid white;
    border-left:15px solid white ;
    border-right:15px solid white ;
  }
  .banner h4 {
    font-size: 48px;
    text-shadow: 17px 19px 9px black;
  }
  
  .art__img__wrapper {
    height: 300px;
  }
  .art__img__wrapper img {
    object-fit: cover;
  }
  
  .art__show {
    background-color: #f0eeed;
  }
  
  .blog__section {
    background-color: #fff;
  }
  
  .blog__section .content {
    padding: 32px 45px;
    height: 280px;
  }
  
  .content h6 {
    font-size: 12px;
    color: #709da7;
    text-transform: uppercase;
  }
  
  .content h5 {
    font-size: 21px;
    color: #000000;
    margin-bottom: 10px;
  }
  
  .content p {
    font-size: 14px;
    color: #baced1;
  }
  
  .player {
    /* background-color: #709da7; */
    margin: 3px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  .card-bloggg{
    width: 25%;
  }
  @media (max-width: 1190px) {
    .card-bloggg{
      width: 100%;
    }
    .comingsoon-text{
      font-size: 32px;
    }
    .landing-vector{
      height: 9px;
      width: 30px;
      background-color: #709da7;
      border-radius: 20px;
    }
  }
  @media (max-width: 990px) {
    .card-bloggg{
      width: 100%;
    }
    .virtual {
      font-size: 80px;
      color: #fff;
      /* font-family: "../../assets/fonts/GoogleSans-Regular.ttf"; */
      /* text-shadow: 172px 20px 9px black; */
    }
    .banner h4 {
      font-size: 40px;
      text-shadow: 17px 19px 9px black;
    }
    .comingsoon-text{
      font-size: 28px;
    }
 
  }
  @media (max-width:800px) {
    .comingsoon-text{
      font-size: 22px;
    }
    .landing-vector{
      height: 6px;
      width: 20px;
      background-color: #709da7;
      border-radius: 20px;
    }
  }
  @media (max-width: 660px) {
    .virtual {
      font-size: 60px;
      color: #fff;
      /* font-family: "../../assets/fonts/GoogleSans-Regular.ttf"; */
      /* text-shadow: 172px 20px 9px black; */
    }
    .banner h4 {
      font-size:35px;
      text-shadow: 17px 19px 9px black;
    }
    .comingsoon-text{
      font-size: 18px;
    }
    .landing-vector{
      height: 6px;
      width: 10px;
      background-color: #709da7;
      border-radius: 20px;
    }
  }
  @media (max-width: 450px) {
    .virtual {
      font-size: 40px;
      color: #fff;
      /* font-family: "../../assets/fonts/GoogleSans-Bold.ttf"; */
      /* text-shadow: 172px 20px 9px black; */
    }
    .banner h4 {
      font-size: 25px;
      text-shadow: 17px 19px 9px black;
    }
  }
  @media (max-width:390px) {
    .virtual{
      font-size: 22px;
    }
    .comingsoon-text{
      font-size: 12px;
    }
    .landing-vector{
      height: 3px;
      width: 5px;
      background-color: #709da7;
      border-radius: 20px;
    }
    .banner-button{
     font-size: 12px;
      padding: 10px 10px;
      border-radius: 30px;
      border: 1px solid #709DA7;
      font-weight: 300;
  
    }
    
  }