.searchinputmodule{
    padding: 5px 10px;
    border-radius: 5px;
    width: 240px;
    border: 2px solid #73a7b1;
}
.searchinputmodule:focus{
    border: 2px solid #73a7b1; /* Change the border color or style as needed */
    outline: none;

}


.footerinputmodule{
    padding: 5px 0px;
    width: 650px;
   background: transparent;
  border: 0px;
    border-bottom: 1px solid #4C4948;
;
   
    
}
.footerinputmodule:focus{
    border: 0px;
    outline: none;
    border-bottom: 1px solid #4C4948;


}

.footerinputmodule::placeholder{
    color: #B8CCCF;
}
.input-container {
    position: relative;
  }

  .input-container::after {
    content: 'SEARCH \02C3';
    font-size: 16px; 
    position: absolute;
    top: 50%;
    right: 0px; 
    transform: translateY(-50%);
    cursor: pointer; 
    color: #fcfcfc; /* Adjust the color as needed */
  }
  @media (max-width:1750px) {
   
    .footerinputmodule{
        width: 600px;
    }
  }
  @media (max-width:1550px) {
   
    .footerinputmodule{
        width: 500px;
    }
  }
  @media (max-width:1450px) {
    .searchinputmodule{
      width: 180px;
  }
  }
  @media (max-width:1250px) {
   
    .footerinputmodule{
        width: 400px;
    }
  }
  @media (max-width:1150px) {
    .searchinputmodule{
        width: 100px;
    }
    .footerinputmodule{
        width: 350px;
    }
  }
  @media (max-width:990px) {
   
    .footerinputmodule{
        width: 300px;
    }
    .searchinputmodule{
        width: 150px;
    }
  }