.main-player-container{
  
    width: 100%;
    background-color: #709AA4;
   
  
    z-index: 1;
 
 }
 .main-dropdown{
transform: translate(25px,-10px) !important;
 }

 .main-inner-content{
     display: flex;
     padding: 10px ;
     justify-content: space-between;
 }
 .main-player-image{
     display: flex;
     align-items: center;
     justify-content: center;
 }
 .main-play-button{
     display: flex;
     align-items: center;
     justify-content: center;
 }
 .main-artist-title{
     display: flex;
     align-items: center;
     justify-content: start;
     width: 200px;
     
 }
 .main-music-type{
     display: flex;
     align-items: center;
     justify-content: center;
 }
 .main-player-timer{
     display: flex;
     align-items: center;
     justify-content: center;
 }
 
 
 
 .main-progress-bar {
     display: flex;
     align-items: center;
     justify-content: center;
     height: 10px;
     width: 500px;
     background-color: #e6e3e3;
     position: relative;
   }
   
   .main-progress-indicator {
     height: 100%;
     background-color: #137716; /* Green color for the progress indicator */
     position: absolute;
     top: 0;
     left: 0;
   }
 .main-player-options{
     display: flex;
     align-items: center;
     justify-content: center;
 }
 .main-player-icons{
     padding: 0px 10px;
 }
 
 .main-small-bar{
     display: none;
 }
 
 .main-big-bar{
     display: flex;
     justify-content: center;
     align-items: center;
 }
 
 @media (max-width:865px) {
    .main-dropdown{
        transform: translate(-27px,-10px) !important;
         } 
 }
 
  @media (max-width:1250px) {
     .main-progress-bar{
         width: 420px;
     }
     .main-artist-title{
        width: 120px;
    }
  }
  @media (max-width:1100px) {
     .main-progress-bar{
         width: 320px;
     }
  }
  @media (max-width:990px) {
     .main-progress-bar{
         width: 200px;
     }
     .main-player-icons{
         padding: 0px 6px;
     }
  }
  
  @media (max-width: 800px) {
   .main-big-bar{
     display: none;
   }
  .main-small-content{
     display: block;
     width: 100%;
  }
  .main-small-progress-bar {
     display: flex;
     align-items: center;
     justify-content: center;
     height: 5px;
     width: 100%;
     background-color: #ddd;
     position: relative;
   }
   
   .main-small-progress-indicator {
     height: 100%;
     background-color: #137716; /* Green color for the progress indicator */
     position: absolute;
     top: 0;
     left: 0;
   }
 
   .main-artist-title{
    width: 100px;
}
   }
   @media (max-width:500px) {
     .main-player-icons{
         padding: 0px 2px;
     }
     .main-music-type{
         display: none;
     }
   }