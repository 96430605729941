.folderlist-cardarea{
    height: 390px;
    padding: 0 10px;
}
.folderlist-card{
    height: 390px;
    max-width: 330px;
}
.folderlist-imagelength{
height: 320px;
}





.folderDetail-row{
padding: 0 150px;
}

.folderDetail-card{
width: 330px;
    height: 390px;

}
.folderDetail-cardArea{
display: flex;
justify-content: center;
}
.folderDetail-imagelength{
height: 320px;
}





@media (max-width:1300px) {
    .folderlist-cardarea{
        height: 350px;
    }
    .folderlist-card{
        height: 350px;
    }
    .folderlist-imagelength{
    height: 300px;
    }  
}
@media (max-width:1150px) {
    .folderlist-cardarea{
        height: 300px;
    }
    .folderlist-card{
        height: 300px;
    }
    .folderlist-imagelength{
    height: 280px;
    }  
}
@media (max-width:950px) {
    .folderlist-cardarea{
        height: 250px;
    }
    .folderlist-card{
        height: 250px;
    }
    .folderlist-imagelength{
    height: 220px;
    }  
}
@media (max-width:767px) {
    .folderlist-cardarea{
        height: 320px;
       margin: 10px 0;
    }
    .folderlist-card{
        height: 300px;
    }
    .folderlist-imagelength{
    height: 270px;
    }  
}




.test-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0px 110px;
  }
  
  .test-card {
    flex: 1 0 calc(25% - 20px); /* Initially set to show 4 items in a row */
    margin-bottom: 20px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
   
  }
  @media screen and (max-width: 1500px) {
    .test-row{
        padding: 0px 100px;
    }
    
  }
  
  @media screen and (max-width: 1200px) {
    .test-row{
        padding: 0px 60px;
    }
    .test-card {
        display: flex;
        justify-content: center;
      flex: 1 0 calc(33.33% - 20px); /* Adjust to show 3 items in a row on smaller screens */
    }
  }
  
  @media screen and (max-width: 900px) {
    .test-row{
        padding: 0px 20px;
    }
    .test-card {
        display: flex;
        justify-content: center;
        
      flex: 1 0 calc(50% - 20px); /* Adjust to show 2 items in a row on even smaller screens */
    }
  }
  
  @media screen and (max-width: 600px) {
   
    .test-card {
      flex: 1 0 calc(100% - 20px); /* Adjust to show 1 item in a row on the smallest screens */
      justify-content: center;
      display: flex;
    }
  }