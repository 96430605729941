/* Default font size */
.TableText {
  font-size: 24px;
  font-weight: 600;
  color: #000;
 
}

/* Adjust font size for small screens and up */
@media (max-width: 470px) {
  .TableText {
    font-size: 16px;
  }
  .TableWidth {
     width: 250px;
    }

 
}
@media (max-width: 576px) {
  .TableText {
    font-size: 14px;
  }
  .TableWidth {
     width: 250px;
    }

 
}


/* Adjust font size for medium screens and up */
@media (max-width: 768px) {
  .TableText {
    font-size: 16px;
  }
 
}

/* Adjust font size for large screens and up */
@media (max-width: 992px) {
  .TableText {
    font-size: 18px;
  }
  .TableWidth {
    width: 250px !important; 
   }
}
