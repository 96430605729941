
.maininputdropdown{
  padding: 10px 20px;
  border-radius: 10px 0px 0px 10px;
  color: #73a7b1;
  border: white;
  box-shadow: 5px 0px 5px #CACACA;
  z-index: 1;

}
.maininputdropdown:focus{
  outline: none;
}

.main-form :hover {
  border:'transparent'
}


.gallery-cardImage{
height: 320px;

}
.gallery-card{
height: 390;
}
.gallery-cardArea{
height: 390;
max-width: 350px;
}

.bold-search-icon {
  font-weight: bolder !important;
}

.mainsearchinput{
  padding: 10px 10px;
  width: 100%;
  border: 2px solid #73a7b1;
  border-radius: 5px 0px 0px 5px;
}
.mainsearchinput::placeholder{
  font-weight: 600;
  font-size: 18px;
  color: lightgray;
  margin-left: -2rem;
}
.mainsearchinput:focus{
  border: 4px solid #73a7b1; /* Change the border color or style as needed */
  outline: none;

}


.background-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .gallary-container{
    margin: 0px 90px;
  }
 
  .rana:hover {
    cursor: pointer;
  }



  .card-gallary {
    border: 1px solid #ddd;
    margin: 8px;
    text-align: center;
  }
  
  .card-gallary img {
    width: 100%;
    height: auto;
  }
  
  /* CardsGrid.css */
  
  .cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 3px;
  }
   
.cards-grid-column {
  background-clip: padding-box;
  margin-right: 16px; /* Adjust for card margins */
  width: auto !important;
}

@media (max-width: 700px) {
  .cards-grid {
    margin-left: 0;
  }

  .cards-grid-column {
    margin-right: 0;
  }
}
@media  (max-width: 1460px) {
  .gallary-container{
    margin: 0px 60px;
  }
}
@media  (max-width: 1200px) {
  .gallary-container{
    margin: 0px 40px;
  }
}
@media  (max-width: 990px) {
  .gallary-container{
    margin: 0px 20px;
  }
}
@media (max-width:1500px) {
  .gallery-cardImage{
    height: 290px;
    }
    .gallery-card{
    height: 340;
    }
    .gallery-cardArea{
    height: 340;
    }
}
@media (max-width:1300px) {
  .gallery-cardImage{
    height: 250px;
    }
    .gallery-card{
    height: 300;
    }
    .gallery-cardArea{
    height: 300;
    }
}
@media (max-width:1150px) {
  .gallery-cardImage{
    height: 200px;
    }
    .gallery-card{
    height: 250;
    }
    .gallery-cardArea{
    height: 250;
    }
}
@media (max-width:950px) {
  .gallery-cardImage{
    height: 170px;
    }
    .gallery-card{
    height: 200;
    }
    .gallery-cardArea{
    height: 200;
    }
}
@media (max-width:767px) {
  .gallery-cardImage{
    height: 250px;
    }
    .gallery-card{
    height: 300;
    }
    .gallery-cardArea{
    height: 300;
    }

}