.visbilityview{
    display: flex;
}

@media (max-width:1050px) {
    .visbilityview{
        display: block;
    }
    
    .imageAdjust{
        margin-top: 20px;
    }
}