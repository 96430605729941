/* topnavbar.css */
.topnavbar {
  display: flex;
  align-items: center;
  color: #ffffff;
  padding:30px 10px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: transparent;
  transition: background-color 0.3s ease; /* Add a transition for smoother color change */
}

.topnavbar.scrolled {
  background-color: rgba(47, 47, 47, 0.649);
  padding:10px 10px;
}
  .logo {
    font-size: 24px;
    width: 100%;
  }
  

  .menu-icon{
    display: none !important; /* Hide initially on larger screens */
    cursor: pointer;
  }
  .topnavbar .topList ul.innerlist{
    display:flex;
    list-style-type: none;
    width: 100%;
    justify-content: space-between;
  }
  .topnavbar .topList ul.innerlist li.header-title{
    padding: 10px 20px; 
   
    
  }

  .topnavbar .topList ul.innerlist li:hover{
  cursor: pointer; 
   
  }
  .topList{
    display: flex;
    width: 100%;
   
}
  .sidebar-main {
    position: absolute;
    top: 85px;
    right: 0;
    background-color: #333333c7;
    width: 200px;
    padding: 20px;
    z-index: 1;
    display: none; /* Hide initially on larger screens */
    height: 100vh;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.5); /* Add a transition for width */
  }
  .sidebar-main ul li a{
    text-decoration: none;
  }

  /* Add styles for the active <li> element */
.topnavbar .topList ul li a.active {
border-bottom: 2px solid white;
  color: #fff; /* Change text color when active */
 
    
  }
  .topnavbar .topList ul li.active a {
    color: #fff;
  }

.sidebar-main ul{
    list-style-type: none;

}
.sidebar-main ul li.side-title{
    padding: 20px 0px;
    color: white;
}
  
.sidebar-main ul li.side-title a{
 
  color: white;
}

@media (max-width:1450px) {
  .topnavbar .topList ul.innerlist li.header-title{
    padding: 10px 10px; 
   
    
  }
}


 
 @media (max-width:1250px) {
    .topnavbar .topList ul.innerlist li.header-title{
      padding: 10px 0px; 
     
      
    }
  }
  /* Media query for mobile screens */
  @media screen and (max-width: 990px) {
   
    .topList{
        display: none;
    }
  
    .menu-icon{
      display: block !important; /* Show the menu icon on smaller screens */
    }
  
    .sidebar-main {
      display: block; /* Show the sidebar-main on smaller screens when toggled */
    }
    .topnavbar {
        justify-content: end;
    }
  }
  