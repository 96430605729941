




@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

@import url('https://fonts.cdnfonts.com/css/brogte');

.brogte{
  font-family: 'Brogte', sans-serif;

}

  .openSans-300 {
     font-family: 'Open Sans', sans-serif;
      font-weight: 300;
    }
    
  .openSans-400 {
     font-family: 'Open Sans', sans-serif;
      font-weight: 400;
    }
    
  .openSans-500 {
     font-family: 'Open Sans', sans-serif;
      font-weight: 500;
    }
    
    .openSans-600 {
     font-family: 'Open Sans', sans-serif;
      font-weight: 600;
    }
    
    .openSans-700 {
     font-family: 'Open Sans', sans-serif;
      font-weight: 700;
    }
    .openSans-800 {
      font-family: 'Open Sans', sans-serif;
       font-weight: 800;
     }
     
    p,h1,h2,h3,h4,h5,h6,label,input,textarea,li,button,small,strong{
      font-family: 'Open Sans', sans-serif ;
    }
    .project-input::placeholder{
color: #BED3D6 !important;
    }
    .project-input{
      color: #709AA4 !important;
      font-weight: 550 !important;
    }
  
  