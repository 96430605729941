.checkbox-wrapper:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
  
  .checkbox-wrapper label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
 
  
  /* Customizing the checkbox */
  
  .checkbox-wrapper input[type="checkbox"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 1.6em;
    height: 1.6em;
    border-radius: 0.15em;
    margin-right: 0.5em;
    border: 2px solid #F4F4F4;
    outline: none;
    cursor: pointer;
  }
  
  input.checked {
    background-color: #709DA7;
    position: relative;
  }
  
  input.checked::before {
    content: "\2714";
    font-size: 1.5em;
    color: #fff;
    position: absolute;
    right: 1px;
    top: -5px;
  }

  
  .checkbox-wrapper input[type="checkbox"]:disabled {
    border-color: #c0c0c0;
    background-color: #c0c0c0;
  }
  
  .checkbox-wrapper input[type="checkbox"]:disabled + span {
    color: #c0c0c0;
  }
  

  