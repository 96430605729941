.single-container{
  
    width: 75%;
    background-color: #709AA4;
    border: 2px solid white;
    border-radius: 5px;
 
 }
 .single-detail-style{
 height: 75px;
 z-index: 1;
 }
 .single-inner-content{
     display: flex;
     padding: 10px ;
     justify-content: space-between;
 }
 .single-player-image{
     display: flex;
     align-items: center;
     justify-content: center;
 }
 .single-play-button{
     display: flex;
     align-items: center;
     justify-content: center;
 }
 .single-artist-title{
     display: flex;
     align-items: center;
     justify-content: center;
     
 }
 .single-music-type{
     display: flex;
     align-items: center;
     justify-content: center;
 }
 .single-player-timer{
     display: flex;
     align-items: center;
     justify-content: center;
 }
 
 
 
 .single-progress-bar {
     display: flex;
     align-items: center;
     justify-content: center;
     height: 10px;
     width: 250px;
     background-color: #e6e3e3;
     position: relative;
   }
   
   .single-progress-indicator {
     height: 100%;
     background-color: #137716; /* Green color for the progress indicator */
     position: absolute;
     top: 0;
     left: 0;
   }
 
 
 .single-small-bar{
     display: none;
 }
 
 .single-big-bar{
     display: flex;
     justify-content: center;
     align-items: center;
 }
 
 
  @media (max-width:1530px) {
     .single-progress-bar{
         width: 220px;
     }
  }
  @media (max-width:1350px) {
     .single-progress-bar{
         width: 180px;
     }
  }
 
  
  @media (max-width: 1200px) {
   .single-big-bar{
     display: none;
   }
  .single-small-content{
     display: block;
     width: 100%;
  }
  .small-single-progress-bar {
     display: flex;
     align-items: center;
     justify-content: center;
     height: 5px;
     width: 100%;
     background-color: #ddd;
     position: relative;
   }
   
   .small-single-progress-indicator {
     height: 100%;
     background-color: #137716; /* Green color for the progress indicator */
     position: absolute;
     top: 0;
     left: 0;
   }
 
   }
   @media (max-width:500px) {
    
     .single-music-type{
         display: none;
     }
   }