.owner-name-overlay {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 1;
  transition: opacity 0.3s ease;
}

.owner-name-overlay h2 {
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: #000;
}

.art__img__wrapper {
    transform: scale(1);
    transition: transform 0.8s ease;
   
   
  }
  
  .art__img__wrapper:hover {
    transform: scale(1.10);
  }
  

  
.modal-show-landingpage{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 80% !important;
  overflow: auto;
background: none !important;
}
.modal-content-landingpage {
  background-color: #fff;
    overflow-y: auto;
    padding: 2px;
    max-width: 90%;
    height: auto;
    border-radius: 4px;
    padding: 0px 10px;
    overflow: hidden;
   
}
.music-name-abcdefghijklmnopqr {

  top: 35.38%;
  left: 26.12%;
  color: var(--color-white);
}
.bobby-sosen,
.new {

  top: 35.86%;
  left: 26.12%;
}
.new {
  top: 35.38%;
  left: 36.29%;
  color: var(--color-yellow);
}
.div4,
.music-type {
 
  top: 35.62%;
  left: 43.3%;
}
.div4 {
  left: 53.3%;
}