
.custom-input::placeholder {
    color: rgb(190, 201, 202); 
    text-align: center;
  }

  .custom-input-donate::placeholder {
    padding-top: 13px;
    padding-right: 10px;
    outline: none;
    color: rgb(112, 155, 165);
    box-sizing: border-box; 
    opacity: 0.9px;
  }
  


  .card-btn {
    background-color: 'rgb(112, 155, 165)' !important;
    color: white;
  }

  /* Add this to your CSS file */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
  z-index: 3; /* Ensure it's above other elements except the modal */
  display: none; /* Initially hidden */
}

.backdrop.active {
  display: block; /* Display when the modal is active */
}
