.dropdown.no-border .dropdown-toggle {
    border-color: transparent;
    box-shadow: none;
  }
  .top-avatar-image{
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: #539b9d;
    border: 2px solid white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.top-avatar-letter{
   
    font-size: 26px;
    color: white;
    font-weight: 700;

}