.galleryDetail-card{
    width: 330px;
    height: 390px;

}
.gallerydetal-cardArea{
display: block;
}
.image-card-height{
height: 320px;
}
