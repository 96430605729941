.loginImage{
   height: 630px;
   
}
.login-row{
   display: flex;
   width: 100%;
}
.login-text{
  
}
.login-image{
   
}
@media (max-width:1050px) {
   .loginImage{
      
      width: 450px;
   }
}
@media (max-width:950px) {
   .loginImage{
      
      width: 420px;
   }
}
@media (max-width:877px) {
   .loginImage{
      width: 350;
      height: 650px;
   }
}
@media (max-width:650px) {
  .login-row{
   display: block;
   overflow-x: hidden;
  }
  .loginImage{
   width: 100%;
   
  }
}