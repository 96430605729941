.player-container{
  
   width: 100%;
   background-color: #709AA4;
   border: 2px solid white;
   border-radius: 5px;

}
.detail-style{
height: 75px;
z-index: 1;
}
.inner-content{
    display: flex;
    padding: 10px ;
    justify-content: space-between;
}
.player-image{
    display: flex;
    align-items: center;
    justify-content: center;
}
.play-button{
    display: flex;
    align-items: center;
    justify-content: center;
}
.artist-title{
    display: flex;
    align-items: center;
    justify-content: start;
    width: 160px;
    
}
.music-type{
    display: flex;
    align-items: center;
    justify-content: center;
}
.player-timer{
    display: flex;
    align-items: center;
    justify-content: center;
}



.progress-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10px;
    width: 350px;
    background-color: #e6e3e3;
    position: relative;
  }
  
  .progress-indicator {
    height: 100%;
    background-color: #137716; /* Green color for the progress indicator */
    position: absolute;
    top: 0;
    left: 0;
  }
.player-options{
    display: flex;
    align-items: center;
    justify-content: center;
}
.player-icons{
    padding: 0px 5px;
}

.small-bar{
    display: none;
}

.big-bar{
    display: flex;
    justify-content: center;
    align-items: center;
}


 @media (max-width:1530px) {
    .progress-bar{
        width: 280px;
    }
    .artist-title{
        width: 120px;
    }
 }
 @media (max-width:1350px) {
    .progress-bar{
        width: 200px;
    }
 }

 
 @media (max-width: 1200px) {
  .big-bar{
    display: none;
  }
 .small-content{
    display: block;
    width: 100%;
 }
 .small-progress-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5px;
    width: 100%;
    background-color: #ddd;
    position: relative;
  }
  
  .small-progress-indicator {
    height: 100%;
    background-color: #137716; /* Green color for the progress indicator */
    position: absolute;
    top: 0;
    left: 0;
  }

  }

  @media (max-width:800px) {
    .artist-title{
        width: 90px;
    }
  }
  @media (max-width:500px) {
    .player-icons{
        padding: 0px 2px;
    }
    .music-type{
        display: none;
    }
  }