

.art__img__wrapper {
    transform: scale(1);
    transition: transform 0.8s ease;
    cursor: pointer;
  }
  
  .art__img__wrapper:hover {
    transform: scale(1.10);
    z-index: 1;
   
  }
  .img-bor:hover {
    border: 2px solid white;
  }

  
.allpost-bgimg{
    width: 99%;
    height: 70vh;
  
}
.bgimg{
    height: 100%;
    width: 99%;
    -o-object-fit: cover;
    object-fit: cover;
  
}
.modal-card{
    width: 65%;
    /* height: 200%; */
  
    background-color: white;
    box-shadow: 5px 5px 5px rgba(151, 151, 151, 0.445);
    z-index: 1;
    margin-top: -350px;
    /* position: absolute; */
    
}
.modal-img{
    width: 100%;
    height: 55vh;
    
}
.modal-image{
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    /* overflow: hidden; */
    
}
/* .under-card{
    margin-top: 1200px;
} */
.date{
    font-size: xx-large;
    font-weight: bold;
}
.month{
    font-weight: bold;
    margin-top: -20px;
}
.blue-title{
    color: blue;
}
.title-head{
    font-weight: bold;
}
.social-links{
    font-size: 1.8em;
    /* margin-top: 1.5em; */
    color: rgb(126, 189, 245);
    margin-left: 9%;
}
.sld{
    margin-left: -55px;
}
.links-card{
    height: 15rem;
    /* width: 15.5rem; */
    /* border: 1px solid rgb(126, 189, 245); */
    background-color: white;
}
.link-card-heasing {
    color: rgb(57, 159, 248);
    font-weight: 500;
}
.link-card-title{
    color: black;
    font-size: large;
    font-weight: 700;
}
.description{
    color: rgb(57, 159, 248);
    justify-content: center;
}
.sidebar{
    /* display: none; */
    height: auto;
}

@media screen and (max-width:1200px) {
    .sidebar{
      display: none;   
    }
 
}
@media screen and (min-width:1200px) {
    .sidebar1{
      display: none;   
    }
}