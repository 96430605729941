.profile-avatar span {
    font-size: 5rem !important;
   
}
.profile-image{
    height: 200px;
    width: 200px;
    border-radius: 50%;
    background-color: #8f9bb9;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.letter-show{
   
    font-size: 7rem;
    color: white;
    font-weight: 800;

}