
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
  z-index: 3; /* Ensure it's above other elements except the modal */
  display: none; /* Initially hidden */
}

.backdrop.active {
  display: block; /* Display when the modal is active */
}



.music-player-disk {
  width: 100px;
}
.img-music {
  margin-top: 30rem;
}


.btn-down:hover {
  background-color: rgb(112, 155, 165) !important;
}
.btn-donate:hover {
  background-color: #56DB99 !important;
}
.custom-button {
  width: 87%; /* Set a fixed width for uniformity */
}


.disk-container {
    position: relative;
    width: 70px; 
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
  }
  
  .white-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    height: 30%;
    background-color: white;
    border-radius: 50%;
    
  }


  .disk-container-music {
    position: relative;
    width: 570px;
     object-fit: cover;
    height: 570px;
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .white-center-music {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    height: 30%;
    background-color: white;
    border-radius: 50%;
  }
  .imageparent{
   
    height: 570px;
  }
  @media (max-width:600px) {
    .imageparent{
   
      height: 520px;
    }

    .disk-container-music {
     
    
      height: 500px;
     
    }
    
  }
  @media (max-width:425px) {
    .imageparent{
   
      height: 450px;
    }

    .disk-container-music {
     
    
      height: 420px;
     
    }
    
  }
  @media (max-width:350px) {
    .imageparent{
   
      height: 380px;
    }

    .disk-container-music {
     
    
      height: 340px;
     
    }
    
  }
  @media (max-width:280px) {
    .imageparent{
   
      height: 300px;
    }

    .disk-container-music {
     
    
      height: 250px;
     
    }
    
  }

  @media (max-width:1660px) {
    .custom-button{
width: 250px;
    }
 
  }

  

  @media (max-width:320px) {
    .btn-top{
width: 100px;

    }
 
  }

  @media (max-width:580px) {
    .btn-top{
margin: 20px 0;
    }
 
  }
  
  @media (max-width:760px) {
    .img-music{
width: 700px;
    }
 
  }

  @media (max-width:1650px) {
    .img-music{
width: 850px;
margin-top: 31rem;
    }
 
  } 

  @media (max-width:1650px) {
    .img-music{
width: 750px;

    }
 
  } 
  @media (max-width:1150px) {
    .img-music{
width: 650px;
    }
 
  }


  @media (max-width:992px) {
    .img-music{
width: 550px;
    }
 
  }

  @media (max-width:800px) {
    .img-music{
width: 500px;
    }
 
  }

  @media (max-width:770px) {
    .img-music{
width: 480px;
    }
 
  }
  
  @media (max-width:600px) {
    .img-music{
margin-top: 27rem !important;
    }
 
  }

  @media (max-width:426px) {
    .img-music-player{
margin-top: -4rem ;
    }
 
  }

  
  @media (max-width:350px) {
    .img-music-player{
margin-top: -9rem ;
    }
 
  }