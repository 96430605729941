.contact-text {
    font-size: 48px;
    margin-left: 61px;
 
}
.contact-container{
    margin: 0 100px;
}
.carousel-indicators{
    z-index: 0 !important;
}

.contact-map{
    width: 100%;
    height: 640px;
}
.para p{
  

}
.log .tt {
    border-radius: 10px;
    box-shadow: 5px 5px 5px gray;

}

.bb {
    border-radius: 15px;
    border: cadetblue;
    background-color: rgba(238, 237, 235, 255);
    background-color: blue;
    background-color: cadetblue;
    color: white;
}

.th {
    height: 20vh;
}

.right img {
    width: 100%;
    height: 100%;
}

.contact-head {
    margin-left: 4rem;
}

@media only screen and (max-width: 770px) {
    .contact-head {
        margin-left: 4rem;
    }
    .contact-container{
        margin: 0 50px;
    }
}

/* Media query for extra small screens */
@media only screen and (max-width: 576px) {
    .contact-head {
        margin-left: 4rem;
    }
}

@media (max-width: 767px) {

    .contact-text {
        font-size: 48px;
      
margin-left: 0px !important;
    }
    .contact-map{
        height: 400px;
    }
}
.form-control::placeholder {
  
color: rgb(122, 177, 179);
  }

  @media (max-width:600px) {
    
    .contact-container{
        margin: 0 0px;
    }
  }