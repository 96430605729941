
.uploadArtDetail-row{
    display: flex;
    width: 100%;

  }
  .uploadArtDetail-textDetail {
width: 50%;
  }

  .uploadArtDetail-image {
    display: flex;
    justify-content: center;
    align-items: center;
width: 50%;
  }
  @media (max-width:1250px) {
    .uploadArtDetail-row{
        display: block;
    }
    .uploadArtDetail-textDetail{
        width: 100%;
    }
    .uploadArtDetail-image{
        margin: 30px 0;
        width: 100%;
    }
  }