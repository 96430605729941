/* CSS */
.search-input-container {
    position: relative;
  }
  
  .search-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #777; /* Optional: You can change the color of the icon */
  }

  .rana {
    font-family: Inter;
  }
  
  /* navbar.css */
/* Add your existing styles here */

/* Responsive styles for screens smaller than or equal to 768px */
@media (max-width: 768px) {
  .col-md-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-md-2 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }
  .col-md-auto {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }
  .search-icon {
    display: none;
  }
}


  