.steps {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
}
.step-button {
   width: 100%;
   height: 100%;
   border-radius: 20%;
   color: #c54646;
  /* border-color: #709BA5; */
    transition: .4s;
    border: 1px solid lightgray;
}
.step-button[aria-expanded="true"] { 
 background-color: #709BA5;
    color: #709BA5;
    border-bottom: none;
}
.done {
    /* background-color: var(--prm-color); */
    color: #fff;
}
.step-item {

width: 40px;
height: 8px;
    z-index: 100;
    text-align: center;
}
#progress {
  -webkit-appearance:none;
    position: absolute;
    width: 95%;
    z-index: 5;
    height: 10px;
    margin-left: 18px;
    margin-bottom: 18px;
}
/* to customize progress bar */
#progress::-webkit-progress-value {
    background-color: var(--prm-color);
    transition: .5s ease;
}
#progress::-webkit-progress-bar {
    background-color: var(--prm-gray);

}