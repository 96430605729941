
.modal-show-compare {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1 !important;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 90% !important;
  overflow: auto;
  background-color: none !important;
}
.login-img{
  /* width: 400px; */
   height: 610px;

}
.login-imgg{
  /* width: 400px; */
   height: 680px;

}
.modal-content-compare {
  background-color: #fff;
 overflow-x: hidden!important;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

}


.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* Media query for smaller screens */
@media only screen and (max-width: 992px) {
  .modal-content-compare {
    max-width: 90%;
    height: auto;
  }
  .login-img{
    
    height: 200px;
    text-align: center;
 }
 .login-imgg{
    
  height: 200px;
  text-align: center;
}
}

/* Media query for extra small screens */
@media only screen and (max-width: 576px) {
  .login-img{
    
    max-width: 340px;
     text-align: center;
  }
  .login-imgg{
    
    max-width: 340px;
     text-align: center;
  }
  .modal-content-compare {
    max-width: 95%;
    height: 90vh;
  }
}
@media only screen and (max-width: 376px) {
  .login-img{
    
     max-width: 280px;
     text-align: center;
  }
  .login-imgg{
    
    max-width: 280px;
    text-align: center;
 }

}
@media (min-width: 992px) and (max-width: 1200px) {
  .modal-content-compare {
    max-width: 90%;
    height: auto;
  }
}
@media only screen and (min-width: 1200px){
  .modal-content-compare {
    max-width: 90%;
    height: auto;

  }
}